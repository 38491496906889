import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../../components/Layout';
import { LightgalleryItem } from "react-lightgallery";
import img1_1 from '../../assets/images/bemutatoterem/1_1.jpeg'
import img1_2 from '../../assets/images/bemutatoterem/1_2.jpeg'
import img1_3 from '../../assets/images/bemutatoterem/1_3.jpeg'
import img1_4 from '../../assets/images/bemutatoterem/1_4.jpeg'
import img2_1 from '../../assets/images/bemutatoterem/2_1.jpeg'
import img2_2 from '../../assets/images/bemutatoterem/2_2.jpeg'
import img2_3 from '../../assets/images/bemutatoterem/2_3.jpeg'
import img2_4 from '../../assets/images/bemutatoterem/2_4.jpeg'

const BemutatoteremBudapest = () => (
  <Layout page="bemutatoterem-budapest">
    <article id="main">
      <header>
        <h2>SHOWROOM - BUDAPEST</h2>
        <p>
          OUR SHOWROOM WILL IMPRESS SMART HOTEL GUESTS WITH ITS EYE-CATCHING WINE CREATIONS.
        </p>
      </header>
    </article>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
          ABOUT THE HOTEL
          </h2>
          <p>
            The Smart Hotel Budapest**** is Hungary’s first smart wine-themed hotel. It is located in the heart of Budapest, 10 meters from lively Király Street and 800 metres from the Budapest Museum Quarter (Heroes’ Square, the City Zoo and Széchenyi Baths). Our boutique 4-star hotel has 14 industrial designer rooms, boasting smart devices and innovative decoration.
          </p>

          <p>
          Our wellness centre provides complete relaxation for our visitors: a Finnish sauna, a steam room and a tepidarium. The hotel welcomes discerning visitors with seasonal offers and current promotions. It offers a friendly, calm environment for all generations, whether for city visits, leisure or business programmes or tasting traditional Hungarian wines, including those of the Juhász Brothers. Enjoy the Smart experience!
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5">
			<div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail" >
                  <LightgalleryItem src={img1_1}><StaticImage  src="../../assets/images/bemutatoterem/1_1.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_2}><StaticImage   src="../../assets/images/bemutatoterem/1_2.jpeg" alt="" /></LightgalleryItem>
                  </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_3}><StaticImage   src="../../assets/images/bemutatoterem/1_3.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_4}><StaticImage   src="../../assets/images/bemutatoterem/1_4.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>


    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
          ABOUT THE SHOWROOM
          </h2>
          <p>
          Food is our Passion, wine is our Love. Our showroom impresses the Smart Hotel’s guests with eye-catching wine creations. We aim to satisfy our guests and meet their needs at the highest level, right from the start. Our goal is to create the most pleasant environment for business, leisure or family travel.
          </p>
          <p>
          “It will not be like a classic wine bar, so you can’t just walk in from the street. The focus will be on exclusive, responsible wine consumption. You will need to book a table for a minimum of six people in advance, but in return, guests will enjoy full culinary pampering. All the staff members have visited the winery several times and have tasted and know our wines, so they can present them authentically and professionally. At the same time, there is a film about us being shown on the wall-mounted TV. The cuisine will generally be finger food, but if we identify a need, there will be hot dishes on offer all the time later.” 
          <br style={{display: 'block'}} />
          -  Ádám Juhász on the opening of the wine bar (vinoport.hu)

          </p>
          {/* <p>
          A teljes interjú itt!  
          </p> */}
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5">
			<div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail" >
                  <LightgalleryItem src={img2_1}><StaticImage  src="../../assets/images/bemutatoterem/2_1.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_2}><StaticImage   src="../../assets/images/bemutatoterem/2_2.jpeg" alt="" /></LightgalleryItem>
                  </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_3}><StaticImage   src="../../assets/images/bemutatoterem/2_3.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_4}><StaticImage   src="../../assets/images/bemutatoterem/2_4.jpeg" alt="" /></LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    
  </Layout>
);

export default BemutatoteremBudapest;
